import * as React from 'react';

import { Text, BlogImage, BlogLink, BlogVideo } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import videoPoster from '../../../images/gusar-Srecko-glas-R.jpg';
import voiceŠcolouring from '../../../images/educational-corner/blog21/glas-Š-bojenje.png';
import voiceŠKokolingoIsland from '../../../images/educational-corner/blog21/glas-Š-Kokolingo-otok.png';
import voiceŠFortuneWheel from '../../../images/educational-corner/blog21/glas-Š-kolo-srece.png';
import voiceŠStories from '../../../images/educational-corner/blog21/glas-Š-price.png';
import voiceŠTreasureHunt from '../../../images/educational-corner/blog21/glas-Š-sakupljanje-blaga.png';
import voiceŠShop from '../../../images/educational-corner/blog21/glas-Š-trgovina.png';
import videos from '../../../videos';

const PracticeVoiceŠ = () => {
  return (
    <PageNew
      title="KOKOLINGO - Izgovor glasa Š"
      description="Vidjeli ste reklamu za najbrbljaviju papigicu u Hrvatskoj 
      koja, uz svoga prijatelja gusara Srećka, pomaže djeci s izgovorom glasova. 
      Dođite i prošećite s nama Kokolingo svijetom, upoznajte ga malo bolje 
      u pratnji šuškavog glasa Š i odlučite jeste li spremni 
      upustiti se u našu morsku avanturu!"
      headline="Kokolingo i glas Š"
    >
      <Text as="p">
        Vidjeli ste reklamu za najbrbljaviju papigicu u Hrvatskoj koja, uz svoga
        prijatelja gusara Srećka, pomaže djeci s izgovorom glasova. Dođite i
        prošećite s nama <BlogLink>Kokolingo</BlogLink> svijetom, upoznajte ga
        malo bolje u pratnji šuškavog glasa Š i odlučite jeste li spremni
        upustiti se u našu morsku avanturu!
      </Text>
      <Text as="p">
        Glas Š pojavljuje se u dobi između tri i pol i četiri i pol godine. Da
        bi se pravilno usvojio, dijete najprije treba usvojiti glas S. To će vam
        najbolje objasniti naš gusar Srećko koji će vas dočekati nakon što se
        registrirate ili prijavite u aplikaciju i voditi vas kako se ne biste
        izgubili na pučini punoj otoka s mnoštvom različitih vježbi.
      </Text>
      <BlogVideo
        src={videos.S1_SOUND}
        title="Pravilan izgovor glasa Š"
        poster={videoPoster}
      />
      <Text as="p">
        Prva postaja na našem putovanju je Srećkova plaža. Kada na nju dođete,
        vidjet ćete dvije aktivnosti, Gusarsku gimnastiku i Pažljivo slušanje. U
        Gusarskoj gimnastici ponovno će vas dočekati naš gusar Srećko i pokazati
        vježbe disanja i neke osnovne pokrete govornih organa koji su potrebni
        za izgovor glasova općenito, kao i za izgovor glasa Š.
      </Text>
      <Text as="p">
        S Gusarskom gimnastikom nemojte ići prebrzo. Svaki dan napravite tri do
        četiri vježbice koje ćete ponoviti tri do četiri puta. I nikako, ali baš
        nikako, nemojte ih preskočiti! One su važan preduvjet za pravilno
        namještanje govornih organa.
      </Text>
      <Text as="p">
        Pogledajte kako to izgleda u jednostavnoj vježbici „Ograda na palubi“.
      </Text>
      <BlogVideo
        src={videos.S1_EXERCISE_EXAMPLE}
        title="Primjer vjezbe za glas Š"
        poster={videoPoster}
      />
      <Text as="p">
        Kada je gimnastika gotova, možete prijeći na Pažljivo slušanje. Ovdje
        ćete naći dvije vježbe, Sakupljanje blaga i Bojenje, vježbe za
        razlikovanje sličnih glasova. U obje vježbe najprije morate pažljivo
        poslušati zadanu riječ, a zatim odlučiti koji ste glas čuli. Je li to
        bio glas Š ili glas S? Za svaki odgovor bit ćete nagrađeni jednim
        sjajnim novčićem, a do sada je sigurno koji put proletjela i naša
        papigica Koko i obodrila vas da nastavite s radom.
      </Text>
      <BlogImage
        imgSrc={voiceŠTreasureHunt}
        imgAlt="Prikaz igre gdje se za pogodeni glas skuplja blago"
        imgTitle="Sakupljanje blaga"
      />
      <Text as="p">
        Čestitamo, uspješno ste savladali Srećkovu plažu! Pred vama je more koje
        zapljuskuje tri otoka, Otok palmi, Pusti otok i Ledeni otok. Na svakom
        otoku nalaze se vježbe s riječima. Na jednom su riječi u kojima je glas
        Š na početku riječi, na drugome riječi gdje je glas Š u sredini, a na
        trećem otoku odabrane su sve riječi s glasom Š na kraju. Ovdje je mnogo
        zabavnih vježbi, ali, kada se zaigrate, nemojte biti nestrpljivi i ići
        prebrzo! Nekoliko puta ponovite riječi i tek kada ste sigurni da je
        dijete usvojilo glas Š u određenoj poziciji, krenite na sljedeći otok na
        kojemu je glas Š u nekoj drugoj poziciji.
      </Text>
      <BlogImage
        imgSrc={voiceŠKokolingoIsland}
        imgAlt="Prikaz Kokolingo otoka"
        imgTitle="Kokolingo otok"
      />
      <Text as="p">
        Zabavnih vježbi neće vam nedostajati! Što vam je draže, otkrivanje
        skrivene slike komadić po komadić u igri Otkrij ili pomaganje našem
        pingvinu ili majmunčiću da se popnu do svoje nagrade u igri Popni se? U
        obje igre morat ćete ponavljati neobične riječi koje se zovu logatomi.
        Logatomi su izmišljene riječi koje same po sebi ništa ne znače, ali
        služe kao pomoć u vježbanju izgovora glasova. Jer, uz toliko igara i
        toliko vježbi, tko bi samo izmislio toliko različitih riječi!
      </Text>
      <Text as="p">
        Međutim, nemojte brinuti, imamo mi vesele igre i s pravim riječima! U
        odjeljku Riječi nalaze se vježbe Brbljave kartice, Memori kartice i
        Pronađi riječi. Kao i u prethodnim vježbama s logatomima, i u vježbama s
        pravim riječima igrate se različitih igara, pronalazeći i izgovarajući
        skrivene riječi, igrajući memori kartice ili gledajući slike i
        ponavljajući riječi. Za svaki riješeni zadatak nećemo vam zaboraviti
        dati po jedan vrijedan dragulj. Pažljivo ga čuvajte, jer može se
        zamijeniti za puno lijepih stvari u trgovini Koko!
      </Text>
      <BlogImage
        imgSrc={voiceŠcolouring}
        imgAlt="Igra gdje se za pogodeni glas boji slika"
        imgTitle="Bojanje"
      />
      <Text as="p">
        Što je to Prašumski otok? To je naša sljedeća postaja. Ovdje se vježbaju
        kratke rečenice, jer na dugom putovanju po prethodnim otocima već ste
        sigurno jako dobro naučili izgovoriti glas Š u svim pozicijama u riječi.
        Tako ovdje više ne ponavljate, na primjer, riječ miš, nego ćete probati
        reći da se miš sakrio u škrinji. To su vježbe u Kolu sreće. Naravno,
        okušat ćete se i u zanimljivim aktivnostima dopunjavanja rečenica u igri
        Nadopunjavanje i otkriti kamo su se sakrili miševi.
      </Text>
      <BlogImage
        imgSrc={voiceŠFortuneWheel}
        imgAlt="Prikaz igre kolo srece"
        imgTitle="Kolo srece"
      />
      <Text as="p">
        Jeste li savladali i ponovili glas Š u kratkim rečenicama? Ako jeste,
        znači da ste spremni za Otočnu oazu. To je posljednji i najsloženiji
        otok i zato budite strpljivi i vježbajte polako. Ovdje se čitaju i
        ponavljaju priče te se ponavlja ono što se pročitalo. Dvije su
        aktivnosti, Čitajmo zajedno i Pročitajmo i promislimo. Ako su rečenice
        preduge, mogu se podijeliti na dva ili tri dijela. Čitajte ih polako i
        strpljivo, sakupljajte vrijedne dragulje i ne žurite. Još samo malo i
        moći ćete se pohvaliti da ste dobro uvježbali glas Š!
      </Text>
      <BlogImage
        imgSrc={voiceŠStories}
        imgAlt="Prikaz igre gdje se ponavlja procitano"
        imgTitle="Price"
      />
      <Text as="p">
        Svi nas pitaju koliko dugo igrati? Preporučuje se 30 do 45 minuta svaki
        dan, naravno, uz pratnju roditelja ili stručnjaka. Jeste li se možda
        umorili vježbajući? Predahnite nakratko u trgovini Koko, to je
        najomiljeniji otok svih naših korisnika. Ondje možete svoje vrijedno
        zarađene novčiće zamijeniti za vesele dodatke za našu Koko, kape,
        šešire, naočale i još mnogo toga. Tako, kada se Koko sljedeći put pojavi
        da vas ohrabri i potakne da nastavite dalje, bit će obučena baš onako
        kako ste odabrali!
      </Text>
      <BlogImage
        imgSrc={voiceŠShop}
        imgAlt="Prikaz trgovine gdje mozete obuci papigu Koko"
        imgTitle="Trgovina za papigu Koko"
      />
      <SectionBlog />
    </PageNew>
  );
};

export default PracticeVoiceŠ;
